<template>
  <div>
    <!-- Loading State -->
    <div
      v-if="!isLoaded"
      class="spinner-container"
    >
      <b-spinner label="Loading..." />
    </div>

    <!-- Content -->
    <div
      v-else
      class="lead-details font-manrope"
    >
      <!-- Banner -->
      <div class="d-flex">
        <b-img
          class="banner-image"
          :src="leadDetails.bannerUrl || defaultBanner"
          alt="Banner Image"
        />
      </div>

      <!-- Error Messages -->
      <LeadAIError
        v-if="hasError"
        class="justify-self-center mt-4"
        v-bind="errorProps"
      />

      <!-- Lead Details -->

      <div
        v-else
        class="detail-card"
      >
        <div
          class="
            details-container
            custom-detail-container
            p-0
            border-0
            rounded-0
          "
        >
          <LeadAIPreviewCard :lead-details="leadDetails" />
        </div>

        <div class="description-wrapper">
          <div class="detail-description">
            <h5 class="detail-heading">
              About the Lead
            </h5>
            <p class="detail-content">
              {{ leadDetails.aboutUs }}
            </p>
          </div>
          <div class="detail-description">
            <h5 class="detail-heading">
              Lead’s Brand Portfolio
            </h5>
            <p
              v-if="leadDetails.topBrands && leadDetails.topBrands.length"
              class="detail-content"
            >
              {{ leadDetails.topBrands.join(', ') }}
            </p>
            <p
              v-else
              class="detail-content text-center"
            >
              <strong>Portfolio Not Available</strong>
            </p>
          </div>
          <div
            v-if="leadDetails.reasonToSell"
            class="detail-description"
          >
            <h5 class="detail-heading">
              Partnership Insights
            </h5>
            <p
              v-if="leadDetails.reasonToSell"
              class="detail-content"
            >
              {{ leadDetails.reasonToSell }}
            </p>
            <p
              v-else
              class="detail-content text-center"
            >
              <strong>Insights Not Available</strong>
            </p>
          </div>
          <div
            v-if="additionalContacts.length"
            class="detail-description"
          >
            <h5 class="detail-heading">
              Additional contacts
            </h5>
            <p
              v-if="additionalContacts.length"
              class="detail-content"
            >
              <b-table
                responsive
                borderless
                thead-tr-class="contact-table-header"
                tbody-tr-class="contact-table-row"
                :items="additionalContacts"
                :fields="additionalContactsFields"
              >
                <template #cell(phoneNumber)="data">
                  <b-link :href="`tel:${data.item.phoneNumber}`">
                    {{ data.item.phoneNumber }}
                  </b-link>
                </template>
                <template #cell(email)="data">
                  <b-link :href="`mailto:${data.item.email}`">
                    {{ data.item.email }}
                  </b-link>
                </template>
              </b-table>
            </p>
            <p
              v-else
              class="detail-content text-center"
            >
              <strong>Additional contacts Not Available</strong>
            </p>
          </div>
          <b-button
            class="mt-4 ml-1 mr-2 px-sm-3 font-weight-bolder"
            variant="outline-dark"
            @click="backToPreviousPage"
          >
            Back to previous page
          </b-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
 BButton, BImg, BSpinner, BTable, BLink,
} from 'bootstrap-vue'
import { apiToastWarning } from '@/@core/utils/toast'
import { FETCH_LEAD_DETAIL } from '@/store/modules/leadai.module'
import analytics from '@/@core/utils/analytics'
import constants from '@/constants'
import LeadAIPreviewCard from './LeadAIPreviewCard.vue'
import LeadAIError from './LeadAIError.vue'
import { mapContactInfo } from './utils/mapContactInfo'

export default {
  name: 'LeadAiDetail',
  components: {
    BImg,
    LeadAIPreviewCard,
    BSpinner,
    BButton,
    LeadAIError,
    BTable,
    BLink,
  },
  data() {
    return {
      leadId: this.$route.params.leadId,
      leadDetails: {},
      isLoaded: false,
      additionalContactsFields: ['leadName', 'jobTitle', 'phoneNumber', 'email'],
      defaultBanner: require('@/assets/images/banner/default-banner.jpg'),
      startTime: 0,
      error: null,
    }
  },
  computed: {
    hasError() {
      return !!this.error
    },
    additionalContacts() {
      const contacts = [...(this.leadDetails?.contacts ?? [])]
      contacts.splice(0, 1)
      return contacts.map(mapContactInfo)
    },
    errorProps() {
      if (this.error === 'trial') {
        return {
          title: 'Need more leads?',
          topDescription:
            "You've accessed the contact details of 3 leads using Leads AI, which is the limit for non-paying Kingpin customers.",
          bottomDescription:
            'Book a meeting with our Sales Team to explore how Kingpin Leads AI can help you target potential clients that are a perfect fit for you.',
          buttonText: 'Get more tailored leads',
          contactLink: 'https://calendar.app.google/XgMuYFpJUtaXxHqe7',
        }
      }
      if (this.error === 'paid') {
        return {
          title: 'Need more leads?',
          topDescription:
            "You've accessed the contact details of 10 leads using Leads AI, which is the limit for Kingpin customers.",
          bottomDescription:
            'Book a meeting with our Customer Success Team to explore how Kingpin Leads AI can help you target potential clients that are a perfect fit for you.',
          buttonText: 'Get more tailored leads',
          contactLink: 'https://calendar.app.google/XgMuYFpJUtaXxHqe7',
        }
      }
      return {}
    },
  },
  created() {
    this.startTime = Date.now()
    this.fetchLeadDetails()
  },

  beforeDestroy() {
    const endTime = Date.now()
    const timeSpent = (endTime - this.startTime) / 1000 / 60
    analytics.track(constants.TRACKS.PAGES.BRAND_OPENS_LEAD_AI_DETAIL, {
      leadId: this.leadId,
      timeSpent: `${timeSpent.toFixed(2)} minutes`,
    })
  },
  methods: {
    async fetchLeadDetails() {
      try {
        const res = await this.$store.dispatch(FETCH_LEAD_DETAIL, this.leadId)
        this.processLeadDetails(res.data.data)
      } catch (err) {
        this.handleFetchError(err)
      } finally {
        this.isLoaded = true
      }
    },
    processLeadDetails(lead) {
      this.leadDetails = lead
      // Update breadcrumbs
      this.$route.meta.breadcrumb = this.$route.meta.breadcrumb.map(item => {
        if (item.dynamic && item.hasOwnProperty('leadAIName')) {
          item.leadAIName = lead?.storeName ?? '...'
        }
        return item
      })
    },
    handleFetchError(err) {
      if (err.response?.status === 410) {
        this.error = err.response.data?.details?.leadsAiType || 'Unknown error'
      } else {
        console.error(err)
        apiToastWarning(
          'Failed to load the lead details page. Please try again.',
        )
      }
    },
    backToPreviousPage() {
      analytics.track(
        constants.TRACKS.ACTIONS.LEAD_AI.BRAND_CLICKS_BACK_BUTTON,
      )
      localStorage.setItem('persistFilter', 'true')
      this.$router.back()
    },
  },
}
</script>

<style lang="scss">
@import '~@core/scss/base/pages/app-brand-preview.scss';

.lead-details .banner-image {
  width: 100%;
  height: auto;
  object-fit: cover;
}

.spinner-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}
.about-lead {
  flex: 1;
  padding: 20px;
  background-color: #fff;
  border: 1px solid #e0e0e0;
  border-radius: 12px;
}

.detail-descrption {
  margin-left: 10px;
  margin-top: 10px;
  display: block;
  width: 100%;
}
.detail-heading {
  font-family: Manrope;
  font-size: 23.67px;
  font-weight: 700;
  line-height: 35.5px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  margin-bottom: 10px;
}

.detail-content {
  font-family: Manrope;
  font-size: 15px;
  font-weight: 400;
  line-height: 30px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  margin: 0;
}

.description-wrapper {
  width: calc(100% - 385px);
  margin-left: 2rem;
  margin-top: 1rem;
}

@media(max-width: 768px) {
  .description-wrapper {
    margin-left: 0;
    width: 100%;
  }
}

.detail-description {
  margin: 20px 0;
  padding: 20px;
  width: 100%;
  background-color: #fff;
  border-radius: 5px;
  border: 1px solid #E0E0E0
}
.custom-detail-container {
  min-width: 320px;
}

.justify-self-center {
  justify-self: center;
}

.contact-table-header th {
  font-size: 12px !important;
  font-weight: 600;
}

.contact-table-row td {
  padding-top: 15px;
}
</style>
