<template>
  <div
    v-if="leadDetails && Object.keys(leadDetails).length"
    class="p-2"
  >
    <div class="d-flex justify-content-center">
      <div class="logo-wrapper">
        <img
          :src="leadDetails.smallLogoUrl || getDefaultProductImage(100, 100)"
          alt="Lead Logo"
          class="lead-logo"
          @error="handleImageError"
        >
      </div>
    </div>
    <div class="d-flex flex-column">
      <div class="lead-name mt-1 text-center">
        {{ leadDetails.storeName }}
      </div>
      <b-link
        v-if="leadDetails.websiteUrl"
        class="website-link"
        target="_blank"
        :href="getLinkWithHttp(leadDetails.websiteUrl)"
      >
        {{ leadDetails.websiteUrl }}
      </b-link>
      <div class="text-center mt-2">
        <span>
          <location-icon />
        </span>
        <span class="ml-5-px">{{ displayLocation }}</span>
      </div>
      <div
        v-if="leadDetails.category"
        class="elements-center flex-wrap mt-2 lead-departments"
      >
        <b-badge
          variant="custom-secondary"
        >
          {{ leadDetails.category }}
        </b-badge>
      </div>

      <div class="button-container mt-2">
        <AddUserModal ref="addUserModal" />
        <b-button
          class="connect-button"
          variant="primary"
          @click="connect"
        >
          Connect
        </b-button>
      </div>
      <div class="additional-info mt-4">
        <div class="info-item mt-1">
          <span>Store type: </span>
          <span>{{ leadDetails.storeTypes.length ? leadDetails.storeTypes.join(', ') : 'N/A' }}</span>
        </div>
        <div class="info-item mt-1">
          <span>AVG Retail Price: </span>
          <span>{{ leadDetails.averageRetailPrice.length ? leadDetails.averageRetailPrice.join(', ') : 'N/A' }}</span>
        </div>
      </div>
      <div class="contact-info mt-4">
        <p class="contact-info-title underline">
          Contact Info
        </p>
        <div class="info-item">
          <span class="mt-1">Lead Name: </span>
          <span>{{ firstContact && firstContact.leadName ? firstContact.leadName : 'N/A' }}</span>
        </div>
        <div class="info-item">
          <span class="mt-1">Job Title: </span>
          <span>{{ firstContact && firstContact.jobTitle ? firstContact.jobTitle : 'N/A' }}</span>
        </div>
        <div class="info-item">
          <span>Email: </span>
          <b-link
            v-if="firstContact"
            :href="`mailto:${firstContact.email}`"
          >
            {{ firstContact && firstContact.email ? firstContact.email : 'N/A' }}
          </b-link>
        </div>
        <div class="info-item">
          <span>Phone Number:  </span>
          <b-link
            :href="`mailto:${firstContact.email}`"
          >
            {{ firstContact && firstContact.phoneNumber ? firstContact.phoneNumber : 'N/A' }}
          </b-link>
        </div>
      </div>
      <div
        v-if="hasSocialLinks"
        class="mt-3 font-weight-bold"
      >
        <strong>Social Links</strong>
        <div class="d-flex mb-50">
          <b-link
            v-if="leadDetails.linkedinUrl"
            target="_blank"
            :href="getLinkWithHttp(leadDetails.linkedinUrl)"
          >
            <span
              v-if="leadDetails.linkedinUrl"
              class="mr-1"
            > <linked-in-icon /> </span>
          </b-link>

          <b-link
            v-if="leadDetails.instagramUrl"
            class="mr-1"
            target="_blank"
            :href="getLinkWithHttp(leadDetails.instagramUrl)"
          >
            <span
              v-if="leadDetails.instagramUrl"
              class="mr-1"
            > <instagram-icon /> </span>
          </b-link>
          <b-link
            v-if="leadDetails.facebookUrl"
            class="mr-1"
            target="_blank"
            :href="getLinkWithHttp(leadDetails.facebookUrl)"
          >
            <span
              v-if="leadDetails.facebookUrl"
              class="mr-1"
            > <facebook-icon /> </span>
          </b-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
BBadge, BLink, BButton,
} from 'bootstrap-vue'

import LocationIcon from '@/@core/assets/svg-components/LocationIcon.vue'
import InstagramIcon from '@/@core/assets/svg-components/InstagramIcon.vue'
import FacebookIcon from '@/@core/assets/svg-components/FacebookIcon.vue'
import LinkedInIcon from '@/@core/assets/svg-components/LinkedInIcon.vue'
import analytics from '@/@core/utils/analytics'
import constants from '@/constants'
import { getDefaultProductImage } from '@/@core/utils/utils'
import parsePhoneNumberFromString from 'libphonenumber-js'
import AddUserModal from '../wallet/AddUserModal.vue'
import { mapContactInfo } from './utils/mapContactInfo'

export default {
  components: {
    BBadge,
    BLink,
    BButton,
    LocationIcon,
    InstagramIcon,
    FacebookIcon,
    LinkedInIcon,
    AddUserModal,
  },
  props: {
    leadDetails: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      getDefaultProductImage,
    }
  },
  computed: {
    hasSocialLinks() {
      return (
        this.leadDetails?.instagramUrl
        || this.leadDetails?.facebookUrl
        || this.leadDetails?.linkedinUrl
      )
    },
    firstContact() {
      const firstContact = this.leadDetails?.contacts[0]
      return mapContactInfo(firstContact)
    },
    displayLocation() {
      if (!this.leadDetails.country) {
        return 'N/A'
      }
      if (!this.leadDetails.locality) {
        return this.leadDetails.country
      }
      return `${this.leadDetails.country} - ${this.leadDetails.locality}`
    },
  },
  methods: {
    getLinkWithHttp(link) {
      if (!link.startsWith('http')) {
        return `https://${link}`
      }
      return link
    },
    connect() {
      analytics.track(constants.TRACKS.ACTIONS.LEAD_AI.BRAND_CLICKS_CONNECT_BUTTON, {
        leadId: this.leadDetails.id,
        lead_storeName: this.leadDetails.storeName,
      })

      const nameParts = this.firstContact.leadName === 'N/A' ? [] : this.firstContact.leadName.split(' ')
      this.leadDetails.firstName = nameParts[0] ?? 'N/A'
      this.leadDetails.lastName = nameParts[1] ?? 'N/A'
      const modalData = {
        ...this.leadDetails,
        email: this.firstContact.email,
        phoneNumber: this.firstContact.phoneNumber,
      }

      const phoneObject = parsePhoneNumberFromString(this.firstContact.phoneNumber)
      if (phoneObject) {
        modalData.phoneObject = phoneObject
      }
      this.$refs.addUserModal.openModal(modalData)
    },
    handleImageError(e) {
      e.target.src = getDefaultProductImage(100, 100)
    },
  },
}
</script>

<style scoped>
.lead-details-container {
  padding: 20px;
  background-color: #fff;
  border: 1px solid #e0e0e0;
  border-radius: 12px;
}
.logo-wrapper {
  border: 1px solid #000;
  border-radius: 50%;
  padding: 5px;
}
.lead-logo {
  width: 100px;
  height: 100px;
  object-fit: cover;
  border-radius: 50%;
}
.lead-name {
  font-size: 1.5rem;
  font-weight: bold;
  text-align: center;
}
.website-link {
  color: #000; /* Changed to black */
  text-decoration: underline;
  text-align: center;
  display: block;
  margin-top: 5px;
  word-wrap: break-word;
  white-space: normal;
}

.ml-5-px {
  margin-left: 5px;
}

.additional-info,
.contact-info,
.social-links {
  text-align: left;
}

.contact-info-title {
  font-weight: bold;
}

.info-item {
  word-wrap: break-word;
  margin-bottom: 5px;
}

.info-item span:first-child {
  font-weight: bold;
}

.underline {
  text-decoration: underline;
}

.social-links .d-flex > * {
  margin-right: 10px;
}
.media-icon {
  margin-right: 3px;
}
.button-container {
  text-align: center;
}

.connect-button {
  width: 150px;
}
</style>
